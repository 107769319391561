import { OverlayModule } from '@angular/cdk/overlay';
import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, COMPILER_OPTIONS, CompilerFactory, Injectable, Injector, NgModule } from '@angular/core';
import { HAMMER_GESTURE_CONFIG, HammerGestureConfig, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JitCompilerFactory } from '@angular/platform-browser-dynamic';

import { Router } from '@angular/router';
import { ApplicationVersionModule } from '@app/components/application-version/application-version.module';
import { WebViewAlertModule } from '@app/components/web-view-alert/web-view-alert.module';
import { httpInterceptorProviders } from '@app/interceptors';
import {
  AccountService,
  AuthService,
  BillingService,
  ClassroomsService,
  ConfigService,
  DropzoneConfig,
  FeaturesService,
  FilesService,
  LanguageService,
  MeetingsService,
  ProfileService,
  ScreenService,
  TranslationHttpLoader,
  WebsocketService,
} from '@app/services';
import { BillingV2Service } from '@app/services/lingo2-account/billing-v2.service';
import { OpenAiService } from '@app/services/open-ai.service';
import { VocabularyService } from '@app/services/vocabulary.service';
import { environment } from '@environments/environment';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import * as Sentry from '@sentry/angular';
import {
  Lingo2FormsModule,
  AbstractAccountService,
  AbstractBillingService,
  AbstractClassroomsService,
  AbstractConfigService,
  AbstractDropzoneConfig,
  AbstractFeaturesService,
  AbstractFilesService,
  AbstractLanguageService,
  AbstractMeetingsService,
  AbstractProfileService,
  AbstractScreenService,
  AbstractUserService,
  AbstractWebsocketService,
  AbstractBillingV2Service,
  AbstractAuthService,
} from 'lingo2-forms';
import { DateFnsModule } from 'lingo2-ngx-date-fns';
import { OpentokModule } from 'lingo2-tokbox';
import { CookieService, CookieModule, CookieOptions, CookieOptionsProvider } from 'ngx-cookie';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

export function SameServiceFactory(service: Injectable) {
  return service;
}

const DevToolsModule = StoreDevtoolsModule.instrument({
  maxAge: environment.storeDepth || 7,
  logOnly: false,
  connectInZone: true,
});

@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
  public overrides = {
    swipe: { direction: 30 },
  };
}

export function HttpLoaderFactory(inject: Injector) {
  return new TranslationHttpLoader(inject);
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserAnimationsModule,
    EffectsModule.forRoot(),
    HttpClientModule,
    OverlayModule,
    StoreModule.forRoot({}),
    DevToolsModule,
    OpentokModule.forRoot({ apiKey: environment.tokbox_api_key }),
    HammerModule,
    CookieModule.forRoot(),
    DateFnsModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [Injector],
      },
    }),
    Lingo2FormsModule.forRoot({
      accountService: { provide: AbstractAccountService, useFactory: SameServiceFactory, deps: [AccountService] },
      billingService: { provide: AbstractBillingService, useFactory: SameServiceFactory, deps: [BillingService] },
      billingV2Service: { provide: AbstractBillingV2Service, useFactory: SameServiceFactory, deps: [BillingV2Service] },
      classroomsService: {
        provide: AbstractClassroomsService,
        useFactory: SameServiceFactory,
        deps: [ClassroomsService],
      },
      userService: { provide: AbstractUserService, useFactory: SameServiceFactory, deps: [AuthService] },
      featuresService: {
        provide: AbstractFeaturesService,
        useFactory: SameServiceFactory,
        deps: [FeaturesService],
      },
      authService: {
        provide: AbstractAuthService,
        useFactory: SameServiceFactory,
        deps: [AuthService],
      },
      filesService: { provide: AbstractFilesService, useFactory: SameServiceFactory, deps: [FilesService] },
      meetingsService: {
        provide: AbstractMeetingsService,
        useFactory: SameServiceFactory,
        deps: [MeetingsService],
      },
      screenService: { provide: AbstractScreenService, useFactory: SameServiceFactory, deps: [ScreenService] },
      dropzoneConfig: { provide: AbstractDropzoneConfig, useFactory: SameServiceFactory, deps: [DropzoneConfig] },
      languageService: {
        provide: AbstractLanguageService,
        useFactory: SameServiceFactory,
        deps: [LanguageService],
      },
      configService: { provide: AbstractConfigService, useFactory: SameServiceFactory, deps: [ConfigService] },
      profileService: { provide: AbstractProfileService, useFactory: SameServiceFactory, deps: [ProfileService] },
      websocketService: {
        provide: AbstractWebsocketService,
        useFactory: SameServiceFactory,
        deps: [WebsocketService],
      },
    }),
    ApplicationVersionModule,
    AppRoutingModule,
    WebViewAlertModule,
  ],
  providers: [
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    ...httpInterceptorProviders,
    { provide: HAMMER_GESTURE_CONFIG, useClass: MyHammerConfig },
    CookieService,
    VocabularyService,
    DeviceDetectorService,
    OpenAiService,
    TranslateService,
    { provide: COMPILER_OPTIONS, useValue: {}, multi: true },
    { provide: CompilerFactory, useClass: JitCompilerFactory, deps: [COMPILER_OPTIONS] },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  public constructor() {}
}
