import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { environment as ENV } from '@environments/environment';
import { catchError } from 'rxjs';
import { AccessTokenService } from '../services/access-token.service';

const ourRoute = (req) => ENV.auth_ic_our_routes.filter((el) => req.url.includes(el))?.length;
const skipRoute = (req) => ENV.auth_ic_skip_routes.filter((el) => req.url.includes(el))?.length;

/*
 */
export const accessTokenInterceptor: HttpInterceptorFn = (req, next) => {
  const token_srv: AccessTokenService = inject(AccessTokenService);

  // Skip few routes
  if (!ourRoute(req) || skipRoute(req)) {
    return next(req);
  }

  // Force logout if no refresh token present
  if (!token_srv.refreshable()) {
    token_srv.force_logout();
    return next(req);
  }

  // Set Auth
  req = token_srv.getReqWithAuth(req);

  // Vai!
  return next(req).pipe(
    catchError((err) => {
      if (err.status === 401) {
        return token_srv.process_request(req, next);
      } else {
        return next(req);
      }
    }),
  );
};
